<template>
<div class='educationFolders'>
	<transition name='fade'>
		<Scroll v-show='loaded'>
			<DynamicTree 
				:data='stockTree' 
				:editable='false'
				:selected='selected'
				icon='stock' 
				rootTitle='SimpleSet'								
				@select='onSelect'
			/>
			<DynamicTree 
				:data='userTree' 
				:editable='true' 
				:selected='selected'		
				icon='personal' 
				rootTitle='Personal'				
				@select='onSelect'
				@drop='onDrop'
			/>
			<DynamicTree 
				v-for='(teamTree, index) in teamTrees'
				:key='index'
				:data='parseTeamTreeValue(teamTree)' 
				:editable='isTeamTreeEditable(teamTree)' 
				:selected='selected'		
				icon='team' 
				rootTitle='Team'				
				@select='onSelect'
				@drop='onDrop'			
			/>			
			<DynamicTree 
				v-if='orgTree' 
				:data='orgTree' 
				:editable='$store.state.profile.user.orgEducation === "readWrite"' 		
				:selected='selected'		
				icon='organization' 
				rootTitle='Organization'								
				@select='onSelect'
				@drop='onDrop'			
			/>
			<DynamicTree 
				v-for='(linkTree, index) in linkTrees'
				:key='`link-${index}`'
				:data='linkTree' 
				:editable='false' 
				:selected='selected'		
				icon='link' 
				:rootTitle='`Link — ${linkTree.link}`'				
				@select='onSelect'
			/>	

		</Scroll>
	</transition>
	<transition name='fade'><Loading v-show='loading' /></transition>
</div>
</template>

<script>
import { walkTreeData } from 'he-tree-vue'
import { replaceKeysDeep, arrToKeyedObj } from '@/utilities'
import { foldersApi } from '@/services/api/modules/clinician/folders'
import DynamicTree from '@/components/common/DynamicTree'
import Scroll from '@/components/common/Scroll'
import Loading from '@/components/common/Loading'

export default {
	name: 'EducationFolders',
	components: { DynamicTree, Loading, Scroll },
	data() { return {
		loading: false,
		loaded: false,
		stockTree: {},
		userTree: {},
		teamTrees: [],
		orgTree: {},
		linkTrees: []
	}},
	computed: {
		flattenedTree() {
			const flatten = tree => {
				const arr = []
				const fn = (node, parentId, index = 0) => {
					arr.push({ id: node.id, parentId, title: node.title, index })
					if (node.children) node.children.forEach((child, index) => fn(child, node.id, index))					
				}
				fn(tree)
				return arr
			}
			const flattened = flatten(this.userTree).map(node=>({ type: 'user', ...node }))
			flattened.push(...flatten(this.stockTree).map(node=>({ type: 'stock', ...node })))
			if (this.orgTree) flattened.push(...flatten(this.orgTree).map(node=>({ type: 'org', ...node })))
			if (this.teamTrees) this.teamTrees.forEach(teamTree=>{
				const [teamId, tree] = Object.entries(teamTree)[0]
				flattened.push(...flatten(tree).map(node=>({ type: 'team', teamId, ...node })))
			})
			if (this.linkTrees) this.linkTrees.forEach(linkTree=>{
				flattened.push(...flatten(linkTree).map(node=>({ type: 'link', ...node })))
			})
			return arrToKeyedObj(flattened)
		},
		selected() {
			return this.$store.state.manageEducation.folderId
		}
	},
	methods: {
		parseTeamTreeValue(teamTree) {
			return Object.values(teamTree)[0]
		},
		isTeamTreeEditable(teamTree) {
			return this.$store.state.profile.teams[Object.keys(teamTree)[0]].teamEducation === "readWrite"
		},		
		fetch() {
			this.loading = true
			foldersApi.touch('fetchEducationFolders').then(([err, result]) => {
				if (!err) {
					const { folders } = replaceKeysDeep(result, { subordinates: "children" })
					this.stockTree = folders.stock 
					this.stockTree.title = 'Education'
					this.userTree = folders.user 
					this.teamTrees = folders.teams 
					this.orgTree = folders.org
					this.linkTrees = folders.links
				}
				this.loading = false
				this.loaded = true
			})
		},
		onSelect(id) {
			this.$store.dispatch('manageEducation/setFolderId', +id || null)
		},
		onDrop(value) {
			this.$emit('drop', value)
		}
	},
	watch: {
		flattenedTree(a) {
			this.$emit('update', a)
		}
	},
	mounted() {
		this.fetch()
	}
}
</script>

<style lang='scss'>
.educationFolders {
	@include fill;
	position: relative;
	.scrollContent {
		padding-top: $size-gutter * 2;
		padding-left: $size-gutter * 2;
	}
}
</style>