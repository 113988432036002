<template>
<div class='educationItem' :key='renderKey'>
	<div><div class='controls'>
		<EnabledButton @click='newItem' :disabled='educationId==="new"&&!unsaved' :svg='"#plus"' :label='$t("elements.buttons.new")' class='neutral tagPrimary' />
		<EnabledButton @click='save' :disabled='!unsaved' :svg='"#save"' :label='$t("elements.buttons.save")' class='neutral tagSuccess' />
		<EnabledButton @click='copy' :disabled='!this.attrs.id' :svg='"#copy"' :label='$t("elements.buttons.copy")' class='neutral' />	
		<DeleteButton :disabled='!canDestroy' @click='destroy' />
	</div></div>
	<div>
		<Scroll v-if='educationId'>
			<div class='text'>
				<TextInput ref='title' v-model='attrs.title' :placeholder='$t("views.educationManager.phTitle")' :maxlength='100' />
				<RichText v-model='attrs.instructions' :placeholder='$t("views.educationManager.phInstructions")' :maxlength='8000' />
			</div>
			<Resources :resources='attrs.resources' @update='v => attrs.resources = v' />
			<div v-if='showInjuryMap' class='injurymap'><a href="https://www.injurymap.com/free-human-anatomy-illustrations" target='_blank'>Injurymap</a></div>
		</Scroll>
	</div>
	<transition name='fade'><Loading v-show='working' /></transition>			
</div>
</template>

<script>
import { isEqual, pick } from 'lodash'
import uniqid from 'uniqid'
import { educationApi } from '@/services/api/modules/clinician/education'
import { smallModalMixin } from '@/components/common/mixins/modal'
import ConfirmDeleteModal from '@/components/common/modals/ConfirmDelete'
import ConfirmModal from '@/components/common/modals/Confirm'	
import Loading from '@/components/common/Loading'
import EnabledButton from '@/components/common/buttons/Enabled'
import DeleteButton from '@/components/common/buttons/Delete'
import TextInput from '@/components/common/TextInput'
import RichText from '@/components/common/RichText'
import Scroll from '@/components/common/Scroll'
import Resources from './ItemResources'

export default {
	name: 'EducationItem',
	components: { Loading, EnabledButton, DeleteButton, TextInput, RichText, Scroll, Resources },
	mixins: [smallModalMixin],
	props: ['educationId','fresh','folders'],
	data() { return {
		renderKey: 0,
		working: false,
		saved: '',
		attrs: {
			id: null,
			folderId: null,
			created: null,
			updated: null,
			title: '',
			instructions: '',
			resources: []
		}
	}},
	computed: {
		unsaved() {
			return !isEqual(this.saved, JSON.stringify(this.attrs)) && !this.working && this.educationId
		},
		canUpdate() {
			if (this.educationId==='new') return true			
			return this.isFolderWriteable(this.attrs.folderId)
		},
		canDestroy() {
			return this.canUpdate && this.educationId!=='new'
		},		
		showInjuryMap() {
			if (!this.attrs.resources || !this.attrs.resources.length) return false
			const types = this.attrs.resources.map(r => this.$store.state.resources.resources[r.resourceId].type)
			return types.includes('stockImage')
		}
	},
	methods: {
		initDef() {
			const attrs = {
				id: null,
				folderId: null,
				created: null,
				updated: null,
				title: '',
				instructions: '',
				resources: []
			}
			this.$set(this, 'attrs', attrs)
			this.saved = JSON.stringify(this.attrs)
		},		
		parseResult(education) {
			const { id, folderId, created, updated, title, instructions, resources } = education
			resources.forEach(r => r.cid = uniqid())
			this.attrs = { id, folderId, created, updated, title, instructions, resources }
			this.saved = JSON.stringify(this.attrs)
		},		
		isFolderWriteable(folderId) {
			const type = this.folders[folderId] ? this.folders[folderId].type : null
			return (
				type === 'user' ||
				(type === 'org' && this.$store.state.profile.user.orgEducation === 'readWrite') ||
				(type === 'team' && this.$store.state.profile.teams[this.folders[folderId].teamId].teamEducation === 'readWrite')
			)
		},				
		newItem() {
			if (this.educationId==='new') {
				const fn = () => {
					this.initDef()
					this.renderKey++
					this.$router.push({ params: { educationId: 'new' }}).catch(()=>{})				
				}				
				this.showSmallModal(ConfirmModal, {
					title: this.$t('views.confirmExitEducation.title'),
					message: this.$t('views.confirmExitEducation.p1'),
					ok: this.$t('elements.buttons.continue'),
					fn
				})
			} else {
				this.$router.push({ params: { educationId: 'new' }}).catch(()=>{})	
			}
		},		
		fetch() {
			this.working = true
			educationApi.touch('fetch', { id: this.educationId })
				.then(([err, result]) => {
					if (!err) {
						if (!this.fresh) {
							setTimeout(() => {
								if (this.$refs.title) {
									this.$refs.title.focus()
									if (this.attrs.title === "Untitled") this.$refs.title.select()
								}
							}, 0)							
						}
						this.parseResult(result.education)
						this.$store.dispatch('resources/parseResourcePreviews', result.resources)
						this.renderKey++
					}
				})
				.finally(() => this.working = false)
		},
		save() {
			this.working = true
			const { id, title, instructions, resources } = this.attrs
			if (id && this.canUpdate) {
				const payload = { 
					id, title, instructions, 
					resources: resources.map(r => pick(r, ['id','resourceId','caption'])) 
				}
				educationApi.touch('update', payload)
					.then(([err, result]) => {
						if (!err) {
							this.parseResult(result)
							this.$emit('saved')
							this.$store.dispatch('flash/showAction', 'saved')
						}
					})
					.finally(() => this.working = false)		
			} else {
				const fn = savename => {
					this.working = true
					const userRootId = Object.values(this.folders).find(f=>f.type==='user' && !f.parentId).id
					const selected = this.$store.state.manageEducation.folderId
					const folderId = selected && this.isFolderWriteable(selected) ? selected : userRootId
					const payload = { 
						folderId, title: title || 'Untitled', instructions, 
						resources: resources.map(r => pick(r, ['resourceId','caption'])) 
					}
					educationApi.touch('create', payload)
						.then(([err, result]) => {
							if (!err) {
								this.parseResult(result)
								this.$store.dispatch('flash/showAction', 'saved')
								//this.$store.dispatch('manageOutcomes/setFolderId', folderId)
								console.log(this.$store.state.manageEducation.folderId, folderId)
								if (this.$store.state.manageEducation.folderId && this.$store.state.manageEducation.folderId !== folderId) {
									this.$store.dispatch('manageEducation/setFolderId', folderId)
								}
								this.$router.push({ params: { educationId: result.id }})
								this.$emit('saved')
							}
						})
						.finally(() => this.working = false)
				}
				fn()
			}
		},
		copy() {
			this.working = true
			const { folderId, title, instructions, resources } = this.attrs		
			const userRootId = Object.values(this.folders).find(f=>f.type==='user' && !f.parentId).id
			const payload = { 
				folderId: this.canUpdate ? folderId : userRootId,
				title: title + ' copy', 
				instructions, 
				resources: resources.map(r => pick(r, ['resourceId','caption'])) 
			}
			educationApi.touch('create', payload)
				.then(([err, result]) => {
					if (!err) {
						if (this.canUpdate) {
							this.$store.dispatch('flash/showAction', 'copied')
							this.$emit('saved')
						} else {
							this.$store.dispatch('flash/showAction', 'copyPersonal')
						}
						this.parseResult(result)
						this.$router.push({ params: { educationId: result.id }})
						this.$store.dispatch('manageEducation/setFolderId', this.attrs.folderId)
					}
				})
				.finally(() => this.working = false)						
		},
		destroy() {
			const fn = () => {
				this.working = true
				educationApi.touch('destroy', { id: this.attrs.id })
					.then(([err]) => {
						if (!err) {
							this.$emit('saved')
							this.$router.replace({ name: "education" })
						}
					})
					.finally(() => this.working = false)				
			}
			this.showSmallModal(ConfirmDeleteModal, { items: this.attrs.title, fn })				
		},
		updateFolder(folderId) {
			if (!this.unsaved) {
				this.attrs.folderId = folderId
				this.saved = JSON.stringify(this.attrs)
			} else {
				this.attrs.folderId = folderId
			}
		}
	},
	watch: {
		educationId(a, b) {
			if (a !== b && a) {
				if (a==='new') {
					this.initDef()
					this.renderKey++		
				} else {
					this.fetch()
				}
			} else if (!a) {
				this.initDef()
			}
		}
	},
	created() { this.initDef() },
	mounted() {
		if (this.educationId && this.educationId !== 'new') this.fetch()
	}
}
</script>

<style lang='scss'>
.educationItem {
	@include fill;
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;

	> div { position: relative; }

	.controls {
		margin: $size-gutter * 2;
		margin-bottom: 0;
		border: 1px solid transparent;
		display: flex;
		> div { 
			flex: 1; 
			background: $color-neutral-panel;
			&.disabled { background: $color-neutral-panel !important; }
		}
	}

	.text { 
		margin: 0 $size-gutter * 2;
		margin-bottom: $size-gutter * 2;
		display: grid;
		grid-template-rows: auto auto;
		grid-gap: 1px;
		background: $color-neutral-shadow;
		border: 1px solid $color-neutral-shadow;
	}

	.richText .ProseMirror { min-height: $size-control-height * 4; }

	.educationItemResources {
		margin: 0 $size-gutter * 2;
	}

	.scrollContent {
		padding: $size-gutter * 2 0;
	}

	.injurymap {
		font-size: $size-font-small;
		padding-left: $size-gutter * 4;
		padding-top: $size-gutter * 4;
		padding-bottom: 0;
		display: block;
		> a { color: $color-black; }
	}

	.ProseMirror { 
		padding: $size-gutter * 3; 
	}
}
</style>