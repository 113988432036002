<template>
<div class='educationManager'>
	<Folders @update='onFolderUpdate' @drop='onFolderDrop' />
	<List ref='list' :folders='folders' @moved='onMoved'  />
	<Item ref='item' :educationId='educationId' :folders='folders' :fresh='fresh' @saved='$refs.list.fetchTop()' />
</div>
</template>

<script>
import { smallModalMixin } from '@/components/common/mixins/modal'	
import ConfirmModal from '@/components/common/modals/Confirm'	
import Folders from './Folders'
import List from './List'
import Item from './Item'

export default {
	name: 'EducationManager',
	components: { Folders, List, Item },
	mixins: [smallModalMixin],
	data() { return {
		entering: true,
		fresh: true,
		folders: {}
	}},
	computed: {
		educationId() { return this.$route.params.educationId }
	},
	methods: {
		onFolderUpdate(val) {
			this.folders = val
		},
		onFolderDrop(val) {
			if (val.folderId !== val.data[0].folderId)
				this.$refs.list.moveEducation(val.data[0].id, val.folderId)
		},
		onMoved({ educationId, folderId }) {
			if (this.$route.params.educationId == educationId) this.$refs.item.updateFolder(folderId)
		},
		checkSaved(next) {
		//	console.log('here', JSON.stringify(this.$refs.item))
			if (this.$refs.item && this.$refs.item.unsaved) {
				this.showSmallModal(ConfirmModal, {
					title: this.$t('views.confirmExitEducation.title'),
					message: this.$t('views.confirmExitEducation.p1'),
					ok: this.$t('elements.buttons.continue'),
					fn: () => next()
				})
			} else {
				next()
			}
		}
	},
	watch: {
		educationId(a) {
			if (a && !this.entering) this.fresh = false
		}
	},
	created() {
		setTimeout(()=>this.entering=false, 500)
		if (!this.$route.params.educationId && this.$store.state.manageEducation.selectedId)
			this.$router.push({ params: { educationId: this.$store.state.manageEducation.selectedId }}).catch(()=>{})
	},
	beforeRouteUpdate(to, from, next) {
		this.checkSaved(next)
	}
}
</script>

<style lang='scss'>
.educationManager {
	min-width: 1020px;
	@include absTopLeft;
	@include fill;
	display: grid;
	grid-template-columns: minmax(220px, 1.5fr) 3fr 4.5fr;
		background: repeating-linear-gradient(
			180deg,
			darken($color-neutral, 5%),
			darken($color-neutral, 5%) 14px,
			$color-neutral-panel 14px,
			$color-neutral-panel 28px
		);
		background-position: 0 -14px;
		> div { background: $color-neutral-panel; &:last-child { margin-left: 1px; }}	
}

/*
@media screen and (max-width: 1200px) {

	.educationManager {

		grid-template-columns: 3fr 4fr;
		.educationList { padding-left: $size-gutter * 2; }
		.educationFolders { display: none; }
		.subfolders { display: grid; }

	}

}
*/
</style>