<template>
<div 
	class='educationListItem' 
	:class='{ selected: $route.params.educationId == item.id }'
	@click='select'
	@mousedown='onMouseDown'
>
	<div class='title'>
		<span class='overflow savename'>			
			{{item.title}}
		</span>
		<svg v-if='!canMove' class='readOnly miniIcon typeIcon'>
			<use xlink:href='#lock' />
		</svg>			
		<span class='overflow path' v-html='path' />
	</div>
</div>
</template>

<script>
import { Distance } from '@/utilities'
import { smallModalMixin } from '@/components/common/mixins/modal'
import { dateTime } from '@/components/common/mixins/dateTime'

export default {
	name: 'EducationListItem',
	mixins: [dateTime, smallModalMixin],
	components: { },
	props: ['item','path','canMove'],
	computed: {
		created() { 
			this.langBackdoor
			return this.dateTime(this.item.created) 
		},
		updated() {
			this.langBackdoor
			return this.dateTime(this.item.created) 			
		}
	},
	methods: {
		onMouseDown(e) {
			if (this.canMove && !this.$store.state.main.mobile) new Distance({
				onPull: () => {
					this.$store.dispatch('bus/loadBus', {
						data: [this.item],
						contentType: 'list'
					})
				},
				e
			})
		},		
		select() {
			if (this.$store.state.bus.bussing) return false
			this.$router.push({ params: { educationId: this.item.id }}).catch(()=>{})
		}
	}
}
</script>

<style lang='scss'>
.educationListItem {
	background: $color-white;
	display: flex;
	align-items: center;
	position: relative;
	height: $size-control-height;
	margin-right: $size-gutter * 2;
	margin-left: 0;

	&:hover { background: $color-neutral-silver; }
	&.selected { background: $color-focus !important; }

	> div {
		flex: 1;
		padding: $size-gutter * 2;
		border-left: 1px solid $color-neutral-shadow;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: left;
		min-width: 0;
		overflow: hidden;
		font-size: $size-font-standard;

		&:last-child {
			border-right: 1px solid $color-neutral-shadow;
		}

		&:not(.delete) {
			cursor: pointer;
		}

		> div > div { font-size: $size-font-small; color: $color-primary-accent; }
	}

	.updated {
		flex: .4;
	}

	.title {
		flex: 1;
		.savename { margin-right: $size-gutter * 2; }
		.path { color: $color-neutral-dark; }
		.miniButton { margin-left: $size-gutter * 2; }
		.typeIcon { color: $color-neutral-dark; margin-right: $size-gutter; }
		> span { white-space: nowrap;}
		> span.path { 
			font-size: $size-font-small; 
		}
	}

	.checkCol {
		flex: 0;
		flex-basis: 45px;
	}


	.overflow { overflow: hidden; min-width: 0; text-overflow: ellipsis; }

}
/*
@media screen and (max-width: 1280px) {

	.educationListItem .title {
		.path { display: none !important; }
	}

}
*/
</style>