<template>
<div class='educationList'>
	<!-- <ListTabs /> -->
	<div class='controls'><div>
		<SearchInput 
			v-model='localSearch'
			@input='fetchSearch'
			@submit='fetchSearchImmediate'
			:placeholder='$t("views.educationManager.phSearchEducation")'
		/></div>
	</div>
	<div class='content'>
		<RecycleScroller
			:items='items'
			:item-size='40'
			:buffer='500'
			class='recycleScroller'
		>
			<template slot='before'>
				<!--
				<div class='createButtonWrapper'>
					<EnabledButton 
						@click='createEducation'
						:svg='"#plus"' :label='$t("elements.buttons.new")' 
						class='createEducation neutral' 
					/>					
				</div>
			-->
				<div class='pathWrapper'>
					<div v-if="folderId" class='fullPath bubble'>
						<div class="content fullPath">
							<span 
								v-for='(node, index) in pathNodes(folderId)' 
								:key='index'
								@click='$store.dispatch("manageEducation/setFolderId", node.id)'
							>/&nbsp;&nbsp;<span>{{node.title}}</span>&nbsp;&nbsp;
							</span>	
							<!--<span v-html="pathTo(folderId, true)" />-->
						</div>
						<CloseButton @click='$store.dispatch("manageEducation/setFolderId", null)' />
					</div>
					<div v-else class='fullPath bubble'>
						<div class="content">
							<span>Results from all folders...</span>
						</div>
					</div>					
					<ResultInfo :total='total' :searched="searched" />
				</div>
				<div v-if='subfolders' class='subfolders'>
					<div 
						v-for='(folder, index) in subfolders' 
						:key='index'
						@click='$store.dispatch("manageEducation/setFolderId", folder.id)'
						class='folder'
					>
						<svg class='smallIcon'>
							<use xlink:href='#folders' />
						</svg>		
						<span class='label'>{{folder.title}}</span>
					</div>
				</div>	
			</template>		
			<template slot='sticky'>
				<TableColumns v-if='items.length'
					module='manageEducation'
					:columns='[{
						label: $t("elements.cols.title"),
						selectable: true,
						sort: "title",
						flex: 1
					}]'
				/>
			</template>
			<template v-slot='{ item }'>
				<ListItem :item='item' :itemKey='item.id' :path='pathTo(item.folderId)' :canMove='canMove(item.folderId)' />
			</template>
			<template slot='after'>
				<AtBottom :action='fetchNext' />
			</template>
		</RecycleScroller>
	</div>
	<transition name='fade'><Loading v-show='working' /></transition>			
</div>
</template>

<script>
import { debounce, isEqual } from 'lodash'
import { educationApi } from '@/services/api/modules/clinician/education'
import RecycleScroller from '@/libs/vueVirtualScroller/components/RecycleScroller'
import ResultInfo from '@/components/common/ResultInfo'
import TableColumns from '@/components/common/TableColumns'
import AtBottom from '@/components/common/AtBottom'
import SearchInput from '@/components/common/SearchInput'
import EnabledButton from '@/components/common/buttons/Enabled'
import CloseButton from '@/components/common/buttons/Close'
import Loading from '@/components/common/Loading'
// import ListTabs from './ListTabs'
import ListItem from './ListItem'

export default {
	name: 'EducationList',
	components: { /* ListTabs, */ Loading, SearchInput,CloseButton, RecycleScroller, TableColumns, AtBottom, ListItem, EnabledButton, ResultInfo },
	props: ['folders'],
	data() { return {
		localSearch: '',
		working: false,
		total: 0,
		items: [],
		fetchSearch: debounce(function(search) {
			this.$store.dispatch('manageEducation/setSearch', search)
				.then(() => this.fetchTop())
		}.bind(this), 1000, { leading: false, trailing: true })
	}},
	computed: {
		/*
		canCreate() {
			const folderId = this.$store.state.manageEducation.folderId
			const type = folderId ? this.folders[folderId].type : 'all'
			return (
				type === 'all' ||
				type === 'user' ||
				(type === 'org' && this.$store.state.profile.user.orgEducation === 'readWrite') ||
				(type === 'team' && this.$store.state.profile.teams[this.folders[folderId].teamId].teamEducation === 'readWrite')
			)
		},
		*/
		/*
		canDeleteSelected() {
			const selectedIds = this.$store.state.manageEducation.selectedIds
			return this.items
				.filter(i=>selectedIds.includes(i.id))
				.every(i=>this.canMove(i.folderId))
		},

		canRenameSelected() {
			const selectedIds = this.$store.state.manageEducation.selectedIds
			if (selectedIds.length!==1) return false 
			const item = this.items.find(i=>i.id===selectedIds[0])
			if (!this.canMove(item.folderId)) return false 
			else return item.savename || true
		},
	*/
		folderId() { return this.$store.state.manageEducation.folderId },
		search() { return this.$store.state.manageEducation.search },
		searched() { return this.$store.state.manageEducation.searched },
		sort() { 
			return {
				col: this.$store.state.manageEducation.sortColumn,
				dir: this.$store.state.manageEducation.sortDirection
			}
		},
		filter() {
			return {
				folderId: this.folderId,
				search: this.search,
				sort: this.sort.col,
				sortDir: this.sort.dir,
			}
		},
		subfolders() {
			if (this.$store.state.manageEducation.folderId) {
				return Object.values(this.folders).filter(f=>f && f.parentId === this.$store.state.manageEducation.folderId).sort((a,b)=>a.index-b.index)
			} else if (!this.$store.state.manageEducation.searched) {
				const stock = { ...Object.values(this.folders).find(f=>f && !f.parentId && f.type==='stock') }
				stock.title = 'SimpleSet Education'
				return [
					stock,
					Object.values(this.folders).find(f=>f && !f.parentId && f.type==='user'),
					...Object.values(this.folders).filter(f=>f && !f.parentId && f.type==='team'),
					Object.values(this.folders).find(f=>f && !f.parentId && f.type==='org')
				].filter(v=>v)
			} else {
				return null
			}
		}
	},
	methods: {
		canMove(folderId) {
			const type = this.folders[folderId] ? this.folders[folderId].type : null
			return (
				type === 'user' ||
				type === 'org' && this.$store.state.profile.user.orgEducation === 'readWrite' ||
				(type === 'team' && this.$store.state.profile.teams[this.folders[folderId].teamId].teamEducation === 'readWrite')
			) 
		},						
		fetchSearchImmediate() {
			this.fetchSearch.flush()
		},
		fetchTop() {
			this.working = true
			return educationApi.touch('fetchList', this.filter).then(([err, result]) => {
				if (!err) {
					this.items = result.items
					this.total = result.total
					this.$store.dispatch('manageEducation/setSearched', this.filter.search)
				}
			})
			.finally(() => this.working = false)
		},
		fetchNext() {
			if (this.items.length < this.total) {
				this.working = true
				return educationApi.touch('fetchList', { skip: this.items.length, ...this.filter }).then(([err, result]) => {
					if (!err) this.items.push(...result.items)
				})
				.finally(() => this.working = false)
			}
		},
		moveEducation(educationId, folderId) {
			this.working = true
			educationApi.touch('move', { educationId, folderId })
				.then(([err]) => {
					if (!err) {
						this.$emit('moved', { educationId, folderId })
						return this.fetchTop()
					}
				})
				.finally(() => this.working = false)			
		},
		pathNodes(folderId) {
			const nodes = []
			const fn = (node) => {
				nodes.unshift({ title: node.title, id: node.id })
				if (node.parentId) fn(this.folders[node.parentId])
			}
			if (this.folders[folderId]) fn(this.folders[folderId])
			return nodes
		},		
		pathTo(folderId, fullPath) {
			const nodes = []
			const fn = (node) => {
				nodes.unshift(node.title)
				if (
					node.parentId && 
					(fullPath || node.id !== this.$store.state.manageEducation.folderId)
				) fn(this.folders[node.parentId])
			}
			if (this.folders[folderId]) fn(this.folders[folderId])
			//	console.log(nodes)
			return "/ " + nodes.join("&nbsp;/ ")			
		}
	},
	watch: {
		folderId(a,b) { if (a!==b) this.fetchTop() },
		sort(a,b) { if (!isEqual(a, b)) this.fetchTop() },
		'$route.params.educationId'(val) { this.$store.dispatch('manageEducation/setSelectedId', val) }
	},
	mounted() {
		this.localSearch = this.search
		this.fetchTop()
	}
}
</script>

<style lang='scss'>
.educationList {
	min-width: 300px;
	@include fill;
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	background: $background-panel;

	.controls {
		margin: $size-gutter * 2;
		margin-bottom: 0;
		margin-left: 0;

		> div {
			border: 1px solid $color-neutral-shadow;
			background: $color-neutral-shadow;
		}
	}

	.content { 
		.pathWrapper { 
			display: flex; 
			align-items: center;
			> div:first-child { margin-right: auto; }
		}			
		.fullPath { 
			font-weight: bold; color: $color-primary-accent; background: none; border: none; 
			> span > span {
				cursor: pointer;
				&:hover { text-decoration: underline; }
			}
			.closeButton { margin-right: auto; margin-left: 0; }
		}			
		position: relative; 
		.createButtonWrapper {
			display: flex;
			width: calc(50% - #{$size-gutter * 2});
			border: 1px solid $color-neutral-shadow;
			margin-top: $size-gutter * 2;
			> div { width: 100%; }
		}
		.tableColumns {
			margin: $size-gutter * 2;
			margin-left: 0;
			margin-bottom: 0;
		}
		.resultInfo {
			margin-top: 1px;
		}
	}


	.subfolders {
		flex-basis: 100%;
		display: none;
		grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
		min-height: $size-control-height;
		padding-bottom: $size-gutter;
		padding-top: $size-gutter * 4;
		border: 1px solid transparent;
		
		.folder {
			margin: 3.5px $size-gutter;
			padding: 0; 
			font-size: $size-font-small;
			align-items: center;
			display: flex;
			padding-left: $size-gutter;
			cursor: pointer;
			color: $color-primary-accent;
			font-weight: bold;
			svg { margin-right: $size-gutter * 2; color: $color-neutral-dark; }
			&:hover { 
				text-decoration: underline;
				svg { color: $color-primary-accent; }
			}
		}		
	}	


	.vue-recycle-scroller__item-wrapper:after {
		left: 0 !important;
		width: calc(100% - #{$size-gutter * 2}) !important;
	}		
}
</style>